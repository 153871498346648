"use strict";

var _ = require('underscore');

module.exports = function() {
  return function(obj) {
    var cbs = {};

    var module = {
      fire: function(event) {
        var args = _.without(arguments, event);

        if (cbs[event]) {
          _.each(cbs[event], function(cb) {
            if (_.isFunction(cb)) {
              cb.apply(cb, args);
            }
          });
        }
      },
      on: function(event, cb) {
        cbs[event] = cbs[event] ? cbs[event] : [];
        if (_.isFunction(cb)) {
          cbs[event].push(cb);
        }
      },
      off: function (event) {
        if (cbs[event]) {
          cbs[event] = null;
        }
      },
      hasEvents: function(event) {
        return cbs[event] ? true : false;
      }
    };

    if (_.isObject(obj)) {
      obj = _.extend(obj, module);
    } else {
      return module;
    }
  };
}();
