"use strict";

var _ = require('underscore');

module.exports = function(element) {
  element = _.isString(element) ? document.querySelector(element) : element;

  var content = null;

  return {
    get: function() {
      if (element) {
        content = window.getComputedStyle(element, ":before").getPropertyValue("content").slice(1, -1);
        content = content.replace('config:', '');
      }
      return content;
    },
    is: function(value) {
      return value === this.get() ? true : false;
    }
  };
};
