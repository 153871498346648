"use strict";

var _ = require('underscore'),
    eventsHandler = require('helpers/events-handler'),
    cssConfigReader = require('helpers/css-config-reader');

module.exports = function() {
  var app = {
    breakpoint: cssConfigReader('body')
  };

  eventsHandler(app);

  return app;
};
