"use strict";

var gsap = require('gsap');

module.exports = {
  TweenLite: window.TweenLite,
  TweenMax: window.TweenMax,
  TimelineLite: window.TimelineLite,
  TimelineMax: window.TimelineMax
};
