"use strict";

module.exports = function() {
  // require('classlist');
  require('matchmedia-polyfill');
  require('matchmedia-polyfill/matchMedia.addListener');
  require('html5-history-api');
  require('picturefill');
  // require('placeholders');
  var svg4everybody = require('svg4everybody');
  svg4everybody();
}();
