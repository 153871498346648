// "use strict";

// polyfills
require('polyfills');


var _ = window._ = require('underscore');

var $ = global.$ = window.$ = window.jQuery = global.jQuery = require('jquery');

require('bootstrap');

require('angular');
require('angular-animate');
require('angular-sanitize');
require('angular-cookies');
require('angular-route');
require('angular-scroll');
// require('angular-ga');
var lity = require('lity');

require('hammerjs/hammer');

var PerfectScrollbar = require('perfect-scrollbar');
// var Ps = require('perfect-scrollbar/src/js/main.js');


require('helpers/gsap');
require('scrollmagic/scrollmagic/uncompressed/plugins/animation.gsap');
require('scrollmagic/scrollmagic/uncompressed/plugins/debug.addindicators');
var ScrollMagic = require('scrollmagic');

var Parallax = require('parallax-js');


window._ = _;
/*console.log('_', window._);*/


// left: 37, up: 38, right: 39, down: 40,
// spacebar: 32, pageup: 33, pagedown: 34, end: 35, home: 36
var keys = {37: 1, 38: 1, 39: 1, 40: 1};

function preventDefault(e) {
  e = e || window.event;
  if (e.preventDefault)
    e.preventDefault();
  e.returnValue = false;
}

function preventDefaultForScrollKeys(e) {
  if (keys[e.keyCode]) {
    preventDefault(e);
    return false;
  }
}

function disableScroll() {
  if (window.addEventListener) // older FF
    window.addEventListener('DOMMouseScroll', preventDefault, false);
  window.onwheel = preventDefault; // modern standard
  window.onmousewheel = document.onmousewheel = preventDefault; // older browsers, IE
  window.ontouchmove = preventDefault; // mobile
  document.onkeydown = preventDefaultForScrollKeys;
}

function enableScroll() {
  if (window.removeEventListener)
    window.removeEventListener('DOMMouseScroll', preventDefault, false);
  window.onmousewheel = document.onmousewheel = null;
  window.onwheel = null;
  window.ontouchmove = null;
  document.onkeydown = null;
}


var mainApp = null;
var Underscore = null;

window.mobilecheck = function () {
  var check = false;
  (function (a) {
    if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(a) || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(a.substr(0, 4))) check = true;
  })(navigator.userAgent || navigator.vendor || window.opera);
  return check;
};
var is_mobile = window.mobilecheck();

var randomCode = function () {
  var text = "";
  var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

  for (var i = 0; i < 15; i++)
    text += possible.charAt(Math.floor(Math.random() * possible.length));

  return text;
};


// on document ready
document.addEventListener("DOMContentLoaded", function () {
  // app


  /*$(".full_slider").owlCarousel({
   loop:false,
   items: 1,
   center: true,
   dots: false
   });*/


  $(window).on('scroll', function () {
    var top = $(window).scrollTop();
    var height = $(window).height();
    var bottom = $(document).height();


    if (top >= 100) {
      $('html').addClass('scrolled');
    }
    else {
      $('html').removeClass('scrolled');
    }

    if (top + height > bottom - 2) {
      $('html').addClass('bottomed');
    }
    else {
      $('html').removeClass('bottomed');
    }
  });

  if ($('html').hasClass('page_homepage')) {
    if (Modernizr.mq('only screen and (min-width: 1025px)')) {
      var scene = document.querySelector('#scene');
      console.log('schene', scene);
      // window.parallaxInstance = new Parallax(scene);
    }

    $(window).on('resize', function () {
      if (Modernizr.mq('only screen and (min-width: 1025px)')) {
        try{
          // window.parallaxInstance.disable();
          // window.parallaxInstance.enable();
        }catch (e) {

        }
      }
      else {
        try{
          // window.parallaxInstance.disable();
        }catch (e) {

        }
      }
    });
  }

  if (Modernizr.mq('only screen and (min-width: 1025px)')) {
    setTimeout(function () {
      $('html').addClass('open_elements');
    }, 200);
  }

  setTimeout(function () {
    $('html').addClass('open_elements');
  }, 200);


  /*var controller = new ScrollMagic.Controller();


  var tween1 = TweenMax.to('#footerbg1', 1, {
      top: 0,
      ease: Power4.easeOut,
  });

  var tween2 = TweenMax.to('#footerbg2', 1, {
      top: 10,
      ease: Power4.easeOut,
  });



  new ScrollMagic.Scene({
      duration: 200,  // the scene should last for a scroll distance of 100px
      offset: -400,      // start this scene after scrolling for 50px,
      triggerElement: '#footer'
  })
      .addIndicators({name: "staggering"})
      .setTween([tween1,tween2])
      .addTo(controller);*/


});

/*@ngInject*/
mainApp = angular.module('mainApp', [
  'ngRoute',
  'ngAnimate',
  'ngCookies',
  'duScroll',
  'ngSanitize'
]);

/*@ngInject*/
mainApp.constant('_', window._);

/*@ngInject*/
mainApp.filter('escape', [
  function () {
    return window.encodeURIComponent;
  }
]);

/*@ngInject*/
mainApp.directive("repeatEnd", [function () {
  return {
    restrict: "A",
    link: function (scope, element, attrs) {
      if (scope.$last) {
        scope.$eval(attrs.repeatEnd);
      }
    }
  };
}]);

/*@ngInject*/
mainApp.run(['$anchorScroll', function ($anchorScroll) {
  $anchorScroll.yOffset = 50;   // always scroll by 50 extra pixels
}]);

/*@ngInject*/
mainApp.config([
  '$animateProvider',
  function ($animateProvider) {
    $animateProvider.classNameFilter(/^(?:(?!ng-animate-disabled).)*$/);
  }]);

/*@ngInject*/
mainApp.config([
  '$interpolateProvider',
  function ($interpolateProvider) {
    $interpolateProvider.startSymbol('{[{').endSymbol('}]}');
  }]);

/*@ngInject*/
mainApp.config([
  '$httpProvider',
  function ($httpProvider) {
    $httpProvider.defaults.withCredentials = true;
  }]);

/*@ngInject*/
mainApp.config([
  '$routeProvider',
  '$locationProvider',
  function ($routeProvider,
            $locationProvider) {

    $locationProvider.html5Mode(true);

  }]);

/*@ngInject*/
mainApp.controller("siteController", ['$scope', '$timeout', '$document', function ($scope, $timeout, $document) {

  var _this = this;

  _this.user = {};
  _this.user.country = APP_COUNTRY;
  _this.user.zone = APP_ZONE;
  _this.user.language = APP_LANGUAGE;
  _this.pages = APP_PAGES;

  this.menu = {
    status: 'closed',
    open: function () {
      this.status = 'opened';
    },
    close: function () {
      this.status = 'closed';
    }
  };

  _this.setGeoLocation = function (country, zone) {
    //_this.user.country = country;
    //_this.user.zone = zone;
  };

  _this.overlay = {
    show: false,
    closing: false,
    activeRoute: null,
    randomcode: null,
    routes: {
      productInfo: {
        template: function () {
          var suffix = (APP_LANGUAGE === 'en') ? '_en' : '';
          return '/includes/_catalog_request' + suffix + '.html';
        },
        params: {}
      },
      customizeInfo: {
        template: function () {
          var suffix = (APP_LANGUAGE === 'en') ? '_en' : '';
          return '/includes/_customize_request' + suffix + '.html';
        },
        params: {}
      }
    },
    setRoute: function (route) {
      var th = this;
      if (typeof th.routes[route] !== 'undefined') {
        th.activeRoute = route;
        return true;
      }
      return false;
    },
    setParams: function (params) {
      var th = this;
      th.routes[th.activeRoute]['params'] = params;
    },
    getParams: function () {
      var th = this;
      return th.routes[th.activeRoute]['params'];
    },
    getColor: function () {
      var th = this;
      return (typeof th.routes[th.activeRoute]['params']['color'] !== 'undefined') ? th.routes[th.activeRoute]['params']['color'] : null;
    },
    getProduct: function () {
      var th = this;
      return (typeof th.routes[th.activeRoute]['params']['product'] !== 'undefined') ? th.routes[th.activeRoute]['params']['product'] : null;
    },
    getTop: function () {
      var th = this;
      return (typeof th.routes[th.activeRoute]['params']['top'] !== 'undefined') ? th.routes[th.activeRoute]['params']['top'] : null;
    },
    clearRoute: function () {
      var th = this;
      th.routes[th.activeRoute]['params'] = {};
      th.activeRoute = null;
    },
    getRoute: function () {
      var th = this;

      if (th.hasRoute()) {
        var rt = th.routes[th.activeRoute];
        return (typeof rt !== 'undefined') ? rt : false;
      }
      return false;
    },
    isRoute: function (route) {
      var th = this;
      if (route === th.activeRoute && route !== null) {
        return true;
      }
      return false;
    },
    hasRoute: function () {
      var th = this;
      return th.activeRoute !== null;
    },
    getTemplate: function () {
      var th = this;
      if (th.hasRoute()) {
        var rt = th.getRoute();
        if (th.randomcode === null) {
          th.randomcode = randomCode();
        }
        return rt.template() + '?cache=' + th.randomcode;
      }
      return null;
    },
    openRoute: function (route, params) {
      var th = this;
      if (th.setRoute(route)) {
        if (typeof params !== undefined && !_.isEmpty(params)) {
          th.setParams(params);
        }

        $timeout(function () {
          th.show = true;
          var container = document.querySelector('#overlayScroll');
          window.Ps = new PerfectScrollbar(container, {
            wheelSpeed: 2,
            wheelPropagation: true,
            minScrollbarLength: 20
          });
          if (Modernizr.mq('only screen and (min-width: 768px)')) {
            disableScroll();
          }
          else{
            $('html').scrollTop(0);
          }

        }, 600);
      }
    },
    /*load: function(){
        var th = this;
        console.log('overlay loaded');
        th.show = true;

        if (Modernizr.mq('only screen and (min-width: 768px)')) {
            var container = document.getElementById('overlayScroll');
            Ps.initialize(container, {
                wheelSpeed: 2,
                wheelPropagation: true,
                minScrollbarLength: 20
            });
        }


    },*/
    close: function () {
      var th = this;


      th.closing = true;
      $timeout(function () {
        var par = th.getParams();

        th.clearRoute();
        th.randomcode = randomCode();

        th.closing = false;

        $timeout(function () {

          if (Modernizr.mq('only screen and (min-width: 768px)')) {
            enableScroll();
          }
          else {
            if (typeof par.snapToElement !== 'undefined') {
              $timeout(function () {
                var duration = 600; //milliseconds
                var offset = 0; //pixels; adjust for floating menu, context etc
                var someElement = angular.element(document.getElementById(par.snapToElement));
                $document.scrollToElement(someElement, offset, duration);
              }, 400);
            }
          }

          th.show = false;

        }, 600);
      }, 600);


    }
  };

}]);

/*@ngInject*/
mainApp.controller("sectionController", ['$scope', function ($scope) {

  var _this = this;

}]);

/*@ngInject*/
mainApp.controller("customizeController", ['$scope', '$timeout', function ($scope, $timeout) {

  var _this = this;

  _this.active_elem = 0;

  _this.startSwith = function () {
    _this.active_elem = 0;
    $timeout(switchActive, 6000);
  };

  var switchActive = function () {
    if (_this.active_elem >= 4) {
      _this.active_elem = 0;
    }
    else {
      _this.active_elem++;
    }
    $timeout(switchActive, 6000);
  };

}]);

/*@ngInject*/
mainApp.controller("catalogController", ['$scope', '$element', '_', '$timeout', '$location', '$window', '$rootScope', '$anchorScroll', '$document', '$sce', '$compile', function ($scope, $element, _, $timeout, $location, $window, $rootScope, $anchorScroll, $document, $sce, $compile) {

  var _this = this;

  _this.filters = {};
  _this.all_products = {};
  _this.products = {};
  _this.product = null;
  _this.product_top = null;
  _this.isDetailSwitchingProduct = false;
  _this.noProductsFound = false;
  _this.products_loaded = false;

  var products_grid = null,
    detail_row = null,
    last_opened_product = null;

  _this.detail_open = false;


  _this.init = function () {
    _this.filters = json_filters;
    _this.all_products = json_products;


    if (_this.product !== null) {
      _this.product.selectedimage = 0;
    }

    refreshFilters();
    findProducts();

    if (selected_product !== null) {

      $timeout(function () {
        angular.element('#product' + selected_product).find('a').triggerHandler('click');
      }, 1000);

    }

    $timeout(function () {
      _this.products_loaded = true;
    }, 1000);


  };

  _this.prepareUrl = function (string) {
    return string.replace(/[\s]/g, '-');
  };

  _this.toggleFilter = function ($event, _group, _filter) {

    $event.preventDefault();


    if (!_this.filters[_group][_filter]["enabled"]) {
      return false;
    }

    closeOpenedDetails();

    var res = (_this.filters[_group][_filter]["selected"]) ? false : true;

    if (res === true && _group === 'category') {
      unselectAllFilters();
    }
    else if (res === true && _group !== 'category') {
      unselectGroupFilters(_group);
      unselectGroupFilters('type');
    }


    _this.filters[_group][_filter]["selected"] = res;

    refreshFilters();
    findProducts();

    $scope.updateGrid();

    if (_this.filters[_group][_filter]["selected"] === true) {
      window.ga('send', {
        hitType: 'event',
        eventCategory: 'Products',
        eventAction: 'filter',
        eventLabel: _this.filters[_group][_filter]["name"]
      });
    }

  };

  _this.switchDetailImage = function (i) {
    _this.product.selectedimage = i;

    window.ga('send', {
      hitType: 'event',
      eventCategory: 'Products',
      eventAction: 'image',
      eventLabel: 'Switch image for ' + _this.product.name + ' ' + _this.product.packaging[0] + ' ' + _this.product.weight + 'g' + ' - ' + _this.product.code,
      eventValue: parseInt(i + 1)
    });

  };

  $(window).on("resize.doResize", _.throttle(function () {

    if (Modernizr.mq('only screen and (min-width: 768px)')) {
      $scope.$apply(function () {

        // console.log('il problema è su', 'doResize');
        closeOpenedDetails();
        calculateGrid();

      });
    }

  }, 100));

  $scope.updateGrid = function () {
    $timeout(function () {
      calculateGrid();
    }, 1);
  };

  _this.productSwitch = function ($event, _productCODE) {

    var opened_product = $($event.currentTarget).parent();

    var indx = $('.block__catalog .block__catalog__product').index(opened_product);
    var opened_product_row = findProductRow(indx);

    if (_this.detail_open) {

      var lst_indx = $('.block__catalog .block__catalog__product').index(last_opened_product);
      var riga_ultimo_prodotto_aperto = findProductRow(lst_indx);

      if (opened_product.is(last_opened_product)) {
        // console.log('il problema è su', 'opened_product.is');
        closeOpenedDetails();
      }
      else if (riga_ultimo_prodotto_aperto === opened_product_row) {
        switchProductInRow(_productCODE);
        last_opened_product = opened_product;
      }
      else {
        if (isLastRow(opened_product_row)) {
          if (detail_row === (opened_product_row - 1)) {
            switchProductInRow(_productCODE);
            last_opened_product = opened_product;
          }
          else {
            closeOpenedDetails(true);
            // console.log('il problema è su', '634');

            $timeout(function () {
              var row_to_open = (hasOnlyOneRow()) ? opened_product_row : opened_product_row - 1;
              openUnderRow(row_to_open, _productCODE);
              detail_row = row_to_open;
              last_opened_product = opened_product;
            }, 400);

          }
        }
        else {
          if (detail_row === opened_product_row) {
            switchProductInRow(_productCODE);
            last_opened_product = opened_product;
          }
          else {
            closeOpenedDetails(true);
            // console.log('il problema è su', '652');

            $timeout(function () {
              openUnderRow(opened_product_row, _productCODE);
              detail_row = opened_product_row;
              last_opened_product = opened_product;
            }, 400);

          }
        }
      }
    }
    else {
      if (isLastRow(opened_product_row)) {
        var row_to_open = (hasOnlyOneRow()) ? opened_product_row : opened_product_row - 1;
        openUnderRow(row_to_open, _productCODE);
        detail_row = row_to_open;
        last_opened_product = opened_product;
      }
      else {
        openUnderRow(opened_product_row, _productCODE);
        detail_row = opened_product_row;
        last_opened_product = opened_product;
      }
    }

  };

  _this.closeProduct = function () {
    closeOpenedDetails();
  };

  _this.zoomImage = function (url) {
    var instance = lity(url);
  };

  var findProductRow = function (product_index) {
    var row = 0;
    var elems = 0;

    $.each(products_grid, function (i, v) {
      elems += v.length;
      if (product_index <= elems - 1) {
        row = i + 1;
        return false;
      }
    });
    return row;
  };

  var isLastRow = function (row_number) {
    return (row_number >= products_grid.length) ? true : false;
  };

  var hasOnlyOneRow = function () {
    return products_grid.length <= 1;
  };

  var switchProductInRow = function (_productCODE) {
    _this.isDetailSwitchingProduct = true;
    $timeout(function () {
      assignProduct(findProductByCODE(_productCODE));
    }, 200);
    $timeout(function () {
      _this.isDetailSwitchingProduct = false;
    }, 400);
  };

  var openUnderRow = function (row, _productCODE) {
    var snap = createSnap();
    var snap_id = snap.attr('id');
    var _elem = products_grid[row - 1][(products_grid[row - 1]['length'] - 1)];
    _elem.after(snap);
    assignProduct(findProductByCODE(_productCODE));
    var _pos_detail = snap.offset();
    _this.product_top = _pos_detail.top;

    _this.detail_open = true;

    var templateGoesHere = angular.element(document.getElementById(snap_id));
    templateGoesHere.html('<div ng-include src="\'details_template\'"></div>');

    $compile(templateGoesHere)($scope);

    // console.log($scope, '$scope');

    // $scope.$apply();


    $timeout(function () {
      //$anchorScroll(snap_id);

      var duration = 600; //milliseconds
      var offset = 100; //pixels; adjust for floating menu, context etc
      var someElement = angular.element(document.getElementById(snap_id));
      $document.scrollToElement(someElement, offset, duration);


    }, 400);

  };

  var closeOpenedDetails = function (dontswitchurl) {
    detail_row = null;
    last_opened_product = null;
    _this.isDetailSwitchingProduct = false;
    _this.detail_open = false;

    _this.product = null;

    $('.detail_snap').addClass('detail_snap_close');

    $timeout(function () {
      _this.product_top = null;
      $('.detail_snap').remove();

      if (typeof dontswitchurl === 'undefined' && _this.product === null) {
        $location.path(APP_PAGES[APP_LANGUAGE]['prodotti_base']);
      }

    }, 400);

  };

  var assignProduct = function (val) {
    _this.product = val;
    _this.product.selectedimage = 0;
    if(typeof _this.product.description === 'string'){
      _this.product.description = $sce.trustAsHtml(_this.product.description);
    }
    $.each(_this.product.details, function (i, v) {
      // _this.product.details[i]['value'] = v.value;
      if(typeof v.value === 'string') {
        _this.product.details[i]['value'] = $sce.trustAsHtml(v.value);
      }
    });


    if (val !== null) {
      window.ga('send', {
        hitType: 'event',
        eventCategory: 'Products',
        eventAction: 'open',
        eventLabel: _this.product.name + ' ' + _this.product.packaging[0] + ' ' + _this.product.weight + 'g' + ' - ' + _this.product.code
      });
    }

    //$timeout(function(){
    //    //$('.block__catalog__detail__image').Chocolat();
    //    $('.block__catalog__detail__image').on('click',function(event){
    //        event.preventDefault();
    //        var instance = $(event.currentTarget).Chocolat().data('chocolat');
    //        console.log(instance, 'instance');
    //        instance.api().open();
    //    });
    //},300);


  };

  var calculateGrid = function () {

    var grid = Array();

    var createRow = function () {
      grid.push(Array());
    };

    var _elems = $('.block__catalog__product'),
      last_top = 0,
      first_time = true;

    _elems.each(function (i, v) {
      v = $(v);
      var pos = v.offset(),
        top = parseInt(pos.top);

      if (first_time) {
        first_time = false;
        createRow();
        grid[0].push(v);
        last_top = top;
      }
      else {
        if (top > last_top) {
          createRow();
          grid[grid.length - 1].push(v);
          last_top = top;
        }
        else {
          grid[grid.length - 1].push(v);
          last_top = top;
        }
      }
    });

    products_grid = grid;
    return products_grid;

  };

  var createSnap = function () {
    return $('<div>').addClass('detail_snap new_snap').attr('id', randomCode());
  };

  var findProductByCODE = function (_code) {
    return _.find(_this.all_products, function (prod) {
      return prod.code === _code;
    });
  };

  var unselectAllFilters = function () {
    $.each(_this.filters, function (i, v) {
      $.each(_this.filters[i], function (i2, v2) {
        _this.filters[i][i2]["selected"] = false;
      });
    });
  };

  var unselectGroupFilters = function (group) {
    $.each(_this.filters[group], function (i, v) {
      _this.filters[group][i]["selected"] = false;
    });
  };

  var findTags = function () {
    var tags = [];
    _.each(_this.filters, function (item) {
      var tags_arr = _.where(item, {"selected": true});
      _.each(tags_arr, function (v) {
        tags.push(v['filter-name']);
      });
    });

    return tags;
  };

  var findProducts = function () {

    var tags = findTags();

    if (_.isEmpty(tags)) {
      _this.products = _this.all_products;
    }
    else {
      _this.products = _.filter(_this.all_products, function (item) {
        var res = [];
        _.each(tags, function (v) {
          if (_.contains(item["filter-tags"], v)) {
            res.push('si');
          }
          else {
            res.push('no');
          }
        });

        return !_.contains(res, 'no');
      });


      if (_.isEmpty(_this.products)) {
        _this.noProductsFound = true;
      }
      else {
        _this.noProductsFound = false;
      }
    }

  };

  var getFilterSelected = function (items) {
    var resp = false;
    $.each(items, function (i, v) {

      if (v.selected === true) {
        resp = v;
      }
    });
    return resp;
  };

  var enableFilters = function (items) {
    $.each(items, function (i, v) {
      items[i]['enabled'] = true;
    });
  };

  var refreshFilters = function () {

    var activeFilters = [];
    _.each(_this.filters, function (item) {
      var tags_arr = _.where(item, {"selected": true});
      _.each(tags_arr, function (v) {
        activeFilters.push(v['filter-name']);
      });
    });

    if (!_.isEmpty(activeFilters)) {

      var categorySelection = getFilterSelected(_this.filters.category);
      var packagingSelection = getFilterSelected(_this.filters.packaging);

      if (categorySelection !== false) {
        $.each(_this.filters.packaging, function (i, v) {
          $.each(_this.filters.packaging[i]['depends-on'], function (i2, v2) {
            if (_this.filters.category[v2]["selected"] === false) {
              _this.filters.packaging[i]["enabled"] = false;
            }
            else {
              _this.filters.packaging[i]["enabled"] = true;
              return false;
            }
          });
        });
      }
      else {
        enableFilters(_this.filters.packaging);
      }


      if (categorySelection !== false || packagingSelection !== false) {
        if (categorySelection !== false) {
          $.each(_this.filters.type, function (i, v) {
            $.each(_this.filters.type[i]['depends-on'], function (i2, v2) {
              if (_this.filters.category[v2]["selected"] === false) {
                _this.filters.type[i]["enabled"] = false;
              }
              else {
                _this.filters.type[i]["enabled"] = true;
                return false;
              }
            });
          });
        }
        if (packagingSelection !== false) {
          $.each(_this.filters.type, function (i, v) {
            $.each(_this.filters.type[i]['depends-on-packaging'], function (i2, v2) {
              if (_this.filters.packaging[v2]["selected"] === false) {
                _this.filters.type[i]["enabled"] = false;
              }
              else {
                _this.filters.type[i]["enabled"] = true;
                return false;
              }
            });
          });
        }
      }
      else {
        enableFilters(_this.filters.type);
      }


    }
    else {
      $.each(_this.filters, function (i, v) {
        $.each(_this.filters[i], function (i2, v2) {
          _this.filters[i][i2]["enabled"] = true;
        });
      });
    }

  };

}]);

/*@ngInject*/
mainApp.controller("introController", ['$scope', function ($scope) {

  var _this = this;

  _this.video = {};
  _this.video.open = false;
  _this.video.status = null;

  _this.video.show = function (section_name) {

    if (_this.video.open) {
      return false;
    }

    _this.video.open = true;
    _this.video.status = 'playing';

    build();

    if (typeof section_name !== 'undefined') {
      window.ga('send', {
        hitType: 'event',
        eventCategory: 'Video',
        eventAction: 'play',
        eventLabel: 'Play in ' + section_name + ' page'
      });
    }

  };
  _this.video.play = function () {
    _this.video.status = 'playing';
  };
  _this.video.pause = function () {
    _this.video.status = 'stopped';
  };
  _this.video.hide = function () {

    if (!_this.video.open) {
      return false;
    }

    _this.video.open = false;
    _this.video.status = null;

    remove();

  };

  var build = function () {

  };

  var remove = function () {

  };

  var changeStatus = function (status) {
    _this.video.status = status;
  };

}]);

/*@ngInject*/
mainApp.controller("contactsController", ['$scope', '$http', function ($scope, $http) {

  var _this = this;


  _this.status = null;
  _this.contactsForm = null;
  _this.formError = false;

  var resetMessage = function () {
    _this.message = {
      office: 'Assistenza commerciale'
    };
  };

  resetMessage();

  _this.enableCaptcha = function () {
    _this.message.captcha = true;
  };

  _this.submitForm = function () {
    if (!_this.contactsForm.$invalid) {
      _this.status = false;
      _this.formError = false;

      $http.post('sendmaillll.php', _this.message).then(function (response) {
        if (response.data) {
          _this.status = 'sended';
          resetMessage();
        }
      }, function (err) {
        //console.log(err);
        _this.status = 'server_error';
      });

    }
    else {
      _this.formError = true;
    }
  };

}]);

/*@ngInject*/
mainApp.controller("requestsController", ['$scope', '$http', function ($scope, $http) {

  var _this = this;


  _this.status = null;
  _this.contactsForm = null;
  _this.formError = false;
  _this.product = null;

  var resetMessage = function () {
    _this.message = {
      office: 'Assistenza commerciale',
      captcha: true,
      product: _this.product
    };
  };

  _this.setProduct = function (cd) {
    _this.product = cd;
  };

  resetMessage();

  _this.submitForm = function () {
    if (!_this.contactsForm.$invalid) {
      _this.status = false;
      _this.formError = false;

      $http.post('sendmaillll.php', _this.message).then(function (response) {
        if (response.data) {
          _this.status = 'sended';
          resetMessage();

          if (Modernizr.mq('only screen and (min-width: 768px)')) {
            var container = document.querySelector('#overlayScroll');
            try{
              window.Ps.update(container);
            }catch (e) {

            }
          }

        }
      }, function (err) {
        //console.log(err);
        _this.status = 'server_error';
      });

    }
    else {
      _this.formError = true;
    }
  };

}]);

/*@ngInject*/
mainApp.controller("rotatedCarouselController", ['$scope', function ($scope) {

  var _this = this;

  _this.init = function () {

    if (Modernizr.mq('only screen and (max-width: 767px)')) {

      $("#aziendacarousel").carousel();

      var stage2 = document.getElementById('aziendacarousel');

      var hammertime = new Hammer(stage2);

      hammertime.on('swipeleft', function () {
        $('#aziendacarousel').carousel('next');
      });
      hammertime.on('swiperight', function () {
        $('#aziendacarousel').carousel('prev');
      });

    }


  };


}]);

/*@ngInject*/
mainApp.controller("timelineController", ['$scope', function ($scope) {

  var _this = this;

  _this.init = function () {


    if (Modernizr.mq('only screen and (max-width: 767px)')) {

      $("#timelinecarousel").carousel({
        wrap: false
      });

      $("#timelinecarousel").carousel("pause");

      var stage2 = document.getElementById('timelinecarousel');

      var hammertime = new Hammer(stage2);

      hammertime.on('swipeleft', function () {
        $('#timelinecarousel').carousel('next');
      });
      hammertime.on('swiperight', function () {
        $('#timelinecarousel').carousel('prev');
      });

    }
    else {


      /*
       Se sono su breakpoint da tablet in su, gestisco un listener al load delle immagini
       che lancia un metodo per gestire la larghezza delle immagini della timeline (recalculateImagesWidth)
       anche al resize
       */

      var images = $(".block__timeline__item .block__timeline__image img");
      var totalimages = images.size();
      var i_tot = 1;

      images.each(function () {
        i_tot++;
        if (i_tot >= totalimages) {
          recalculateImagesWidth();
        }
      });

      var tim = null;
      $(window).resize(function () {
        if (tim !== null) {
          clearTimeout(tim);
        }
        tim = setTimeout(recalculateImagesWidth, 100);
      });


      var controller = new ScrollMagic.Controller();

      $('.block__timeline__item').each(function (i, v) {

        new ScrollMagic.Scene({
          duration: 1000,
          offset: -200,
          triggerElement: $(v).get()
        })
          .on("enter leave", function (e) {
            $(v).addClass('animate');
          })
          /*.addIndicators({name: "staggering"})*/
          .addTo(controller);

      });

    }


  };

  /*
   Attribuisce al parent di ogni elemnento immagine una classe
   stretch o large in base alla sua larghezza rapportata al suo
   wrapper
   */
  var recalculateImagesWidth = function () {
    $(".block__timeline__item .block__timeline__image img").each(function () {
      var _elem = $(this);
      var _width = _elem.width();
      var _parent = _elem.parent();
      var _parent_width = _parent.width();
      if (_width < (_parent_width * 80 / 100))
        _parent.addClass('stretch');
      else
        _parent.addClass('large');
    });
  };

}]);

/*@ngInject*/
mainApp.controller("homeController", ['$scope', function ($scope) {

  var _this = this;

  var setPageColors = function (section_bg, footer_bg) {
    $('.main__section, .main__homepage__bg > div').css('backgroundColor', section_bg);
    $('.main__footer__bg_2 svg').css('fill', footer_bg);
    //$('html').css('backgroundColor', footer_bg); //nn funziona su safari
  };

  _this.initCta = function () {
    if (Modernizr.mq('only screen and (min-width: 1025px)')) {

      $('a.blocks_nav__block').hover(function () {

        var _elem = $(this);
        var _prev = _elem.prev();
        var _next = _elem.next();

        _elem.addClass('hovered');
        _prev.addClass('prev_hovered');
        _next.addClass('next_hovered');

      }, function () {

        var _elem = $(this);
        var _prev = _elem.prev();
        var _next = _elem.next();

        _elem.removeClass('hovered');
        _prev.removeClass('prev_hovered');
        _next.removeClass('next_hovered');

      });

    }
  };

  this.initSlides = function () {
    if ($("#homeslides").size() >= 1) {

      $("#homeslides").on('slide.bs.carousel', function (evt) {
        var target = $(evt.relatedTarget);
        setPageColors(target.data('section-bg'), target.data('footer-bg'));
      });


      if (Modernizr.mq('only screen and (max-width: 1025px)')) {

        var stage = document.getElementById('homeslides');

        var hammertime = new Hammer(stage);

        hammertime.on('swipeleft', function () {
          $('#homeslides').carousel('next');
        });
        hammertime.on('swiperight', function () {
          $('#homeslides').carousel('prev');
        });
      }


      setPageColors($("#homeslides .item:first").data('section-bg'), $("#homeslides .item:first").data('footer-bg'));

    }
  };

  /*
  qui riattaccare codice blocchi svg
   */

  this.initNavBlocks = function () {

  };

}]);


angular.element(document).ready(function () {

  window.APP = require('app')();

});
